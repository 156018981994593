import clsx from "clsx"
import { ReactNode } from "react"

type StepHeaderProps = {
  icon: ReactNode
  title: ReactNode
  stepIndex: number
  currentStep: number
}

export default function StepHeader({
  icon,
  title,
  stepIndex,
  currentStep,
}: StepHeaderProps) {
  return (
    <div className="flex h-full w-1/2 flex-col items-center justify-start gap-2">
      <div className="relative flex w-full items-center justify-center">
        <div
          className={clsx(
            "absolute m-0 h-1 w-full transition-all duration-1000",
            currentStep >= stepIndex
              ? stepIndex === currentStep
                ? "bg-gradient-to-r from-primary from-40% to-slate-600 to-60%"
                : "bg-primary"
              : "bg-slate-600",
            stepIndex === 1 && "rounded-l-md",
            stepIndex === 2 && "rounded-r-md",
          )}
        />
        <div
          className={clsx(
            "z-10 flex size-4 items-center justify-center rounded-full transition-all duration-1000",
            currentStep >= stepIndex ? "bg-primary" : "bg-slate-600",
          )}
        >
          {icon}
        </div>
      </div>
      <span className="font-bold">{title}</span>
    </div>
  )
}
