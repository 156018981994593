import { useTranslation } from "react-i18next"
import { Button } from "../ui/button"
import { Link } from "react-router-dom"
import { getCreateDuoPath } from "@/lib/getRoutePath"

export function CreateMatchCard() {
  const { t } = useTranslation()

  return (
    <Button
      asChild
      className="h-[22rem] w-[15rem] items-center justify-center"
      variant="outline"
    >
      <Link
        to={getCreateDuoPath()}
        className="flex h-full w-full animate-pulse items-center justify-center p-0 uppercase opacity-80 transition-transform hover:scale-105 hover:opacity-100"
      >
        {t("Duo.create")}
      </Link>
    </Button>
  )
}
