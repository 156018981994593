import { Skeleton } from "@/components/ui/skeleton"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

import { useQuery } from "@tanstack/react-query"
import { GetAccountPerformance } from "../../../services/request/Account"
import { FormatCurrency } from "../../../utils/currencyHelper"
import { useUser } from "../../../hook/useUser"
import { Eye, EyeOff } from "lucide-react"
import { InfoTooltip } from "@/components/InfoTooltip"
import { SectionTitle } from "@/components/SectionTitle"

export function StreamerPerformance() {
  const { t } = useTranslation()
  const { data: user } = useUser()
  const [showBalance, setShowBalance] = useState(false)

  const { data: perform } = useQuery({
    queryKey: ["getAccountPerformance"],
    queryFn: () => GetAccountPerformance(),
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: !!user?.isTalent,
  })

  return (
    <section className="space-y-4">
      <SectionTitle>
        <span>{t("PerformanceDay.title")}</span>
        <InfoTooltip className="size-4">
          {t("PerformanceDay.tooltip")}
        </InfoTooltip>
      </SectionTitle>

      <div className="grid gap-4 md:grid-cols-2">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              {t("Balance")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center space-x-2">
              {showBalance ? (
                <div className="text-2xl font-bold">
                  {FormatCurrency({
                    value: perform?.amount || 0,
                    currency: perform?.currency || "BRL",
                  })}
                </div>
              ) : (
                <Skeleton className="h-[40px] w-[90px] animate-none" />
              )}

              <Button
                variant="ghost"
                size="icon"
                onClick={() => setShowBalance(!showBalance)}
              >
                {showBalance ? <EyeOff /> : <Eye />}
              </Button>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              {t("Resume.matchesPerformed")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {perform?.matches_played || 0}
            </div>
          </CardContent>
        </Card>
      </div>
    </section>
  )
}
