import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { Info } from "lucide-react"

export function InfoTooltip({
  className,
  children,
}: {
  className: string
  children: string
}) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <Info className={className} />
        </TooltipTrigger>
        <TooltipContent>
          <div className="max-w-80 text-balance text-sm font-normal">
            {children}
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
