import { useTalent } from "../../hook/useTalent"
import { useQuery } from "@tanstack/react-query"
import { GetEnabledProductsByAccount } from "../../services/request/Product"
import { MatchesPanel } from "../../views/MatchesPanel/MatchesPanel"
import { useTranslation } from "react-i18next"
import { IsOnline } from "../../components"
import { useNavigate } from "react-router"
import CardProductSkeleton from "../../components/Skeloton"
import { ChatBubble, RightSquareOutlined } from "../../components/Icons"
import { ProductClientCard } from "../../components/Product/ProductClientCard"
import { Link, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Spinner } from "@/components/Spinner"
import { getPaymentPath } from "@/lib/getRoutePath"

export function TalentPage() {
  const { data: talent, isLoading: talentIsLoading } = useTalent()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const location = useLocation()

  const {
    data: products,
    isLoading: productsIsLoading,
  } = useQuery({
    queryKey: ["getEnabledProducts", talent?.id],
    queryFn: () => GetEnabledProductsByAccount(talent!.id),
    enabled: !!talent,
  })

  if (!talent && !talentIsLoading)
    return (
      <div className="flex items-center justify-center gap-2">
        <h1 className="font-bold uppercase">404 {t("info.TalentNotFound")}</h1>
      </div>
    )

  if (talentIsLoading) {
    return (
      <div className="flex h-[17rem] items-center justify-center">
        <Spinner className="size-10 text-secondary" />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{talent?.name}</title>
        <meta name="description" content={talent?.profile_description} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="flex flex-col gap-y-8 py-4 md:container">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/talents/all">
                {t("TalentsOnline.title")}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{talent?.name}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="border-tertiary flex flex-col items-center gap-6 rounded-sm border border-solid bg-secondary p-4 lg:flex-row lg:items-start lg:justify-start">
          <img
            src={talent?.avatar}
            alt={talent?.name}
            width={150}
            height={150}
            className="transition-duration-500 aspect-square rounded-md object-cover transition-all group-hover:scale-105"
          />
          <div className="flex min-w-[150px] flex-col items-start gap-2 text-foreground">
            <div className="flex flex-col items-start gap-1">
              <h1 className="text-xl font-semibold lg:text-2xl">
                <Link to={`https://twitch.tv/${talent?.name}`}>
                  @{talent?.name}
                </Link>
              </h1>
              <IsOnline
                title={t("live")}
                online={true || !!talent?.current_match}
              />
            </div>
            <p className="text-neutral text-sm leading-6">
              {talent?.profile_description}
            </p>
          </div>
        </div>
        <MatchesPanel
          title={
            <div className="flex w-full items-center justify-between">
              <p className="m-0 flex items-center gap-x-4 text-lg font-bold lg:text-[1.625rem]">
                <RightSquareOutlined />
                {t("Products")}
              </p>
            </div>
          }
        >
          <div className="flex flex-wrap gap-6 pt-6">
            {productsIsLoading ? (
              Array.from({ length: 3 }).map((_, index) => (
                <CardProductSkeleton key={index} />
              ))
            ) : products && products.length > 0 ? (
              products.map((product) => (
                <ProductClientCard
                  product={product}
                  key={product.id}
                  execute={() => {
                    navigation(getPaymentPath({ talent: talent!.name, duoId: product.id }))
                  }}
                />
              ))
            ) : (
              <div className="flex w-full flex-col items-center justify-center gap-y-4 p-10">
                <ChatBubble />
                <span className="text-neutral font-semibold">
                  {t("TalentHasNoMatches")}
                </span>
                <p className="text-neutral m-0">
                  {t("TalentHasNoMatchesInMoment")}
                </p>
              </div>
            )}
          </div>
        </MatchesPanel>
      </div>
    </>
  )
}
