import { Fragment } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../../context/auth"
import { IsOnline } from "../.."
import { useUser } from "../../../hook/useUser"
import { UserPicture } from "../../UserPicture"
import {
  Sidebar as BaseSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuBadge,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar"
import { ChangeLanguage } from "@/components/ChangeLanguage"
import {
  ExternalLink,
  LayoutGrid,
  LogOutIcon,
  PlusCircle,
  User,
  Users,
  Wallet,
} from "lucide-react"
import { env } from "@/utils/env"
import { BecomeATalent } from "./BecomeATalent"
import { getAllStreamersPath, getCreateDuoPath, getEditProfilePath, getOverviewPath } from "@/lib/getRoutePath"

export const Sidebar = () => {
  const { logout } = useAuthContext()
  const { data: user } = useUser()

  const { t } = useTranslation()

  const menuRoutes = [
    {
      key: getOverviewPath(),
      icon: <LayoutGrid />,
      label: t("sidebar.account.overview"),
    },
    {
      key: getEditProfilePath(),
      icon: <User />,
      label: t("sidebar.account.profile"),
    },
  ]

  return (
    <Fragment>
      <BaseSidebar>
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <Link
                  to={getOverviewPath()}
                  className="text-center text-xl font-bold tracking-widest text-white sm:w-[200px]"
                >
                  DUOTOPLAY
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>

        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupContent>
              <div className="mb-6 flex items-center space-x-2 px-1">
                <Link to={getEditProfilePath()}>
                  <UserPicture
                    alt={user?.avatar}
                    src={user?.avatar}
                    size="small"
                  />
                </Link>
                <div>
                  <div className="flex flex-col gap-0.5 text-left text-sm leading-tight">
                    <Link to={getEditProfilePath()}>
                      <span className="truncate font-semibold">
                        @{user?.name}
                      </span>
                    </Link>
                    <span className="truncate text-xs">
                      {user?.isTalent && (
                        <IsOnline
                          title={t("sidebar.online")}
                          online={!!user?.current_match}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </SidebarGroupContent>
          </SidebarGroup>

          <SidebarGroup>
            <SidebarGroupLabel>{t("sidebar.account.title")}</SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {menuRoutes.map((route) => (
                  <SidebarMenuItem key={route.key}>
                    <SidebarMenuButton asChild>
                      <Link to={route.key}>
                        {route.icon}
                        <span>{route.label}</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>

          {user?.isTalent && (
            <SidebarGroup>
              <SidebarGroupLabel>
                {t("sidebar.toStreamers.title")}
              </SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  <BecomeATalent />

                  <SidebarMenuItem>
                    <Link to={getCreateDuoPath()}>
                      <SidebarMenuButton>
                        <PlusCircle />
                        {t("sidebar.toStreamers.createDuo")}
                      </SidebarMenuButton>
                    </Link>
                  </SidebarMenuItem>

                  <SidebarMenuItem>
                    <SidebarMenuButton
                      onClick={() => window.open(env.STRIPE_WALLET_URL)}
                    >
                      <Wallet />
                      {t("sidebar.toStreamers.wallet")}
                    </SidebarMenuButton>
                    <SidebarMenuBadge>
                      <ExternalLink className="size-3" />
                    </SidebarMenuBadge>
                  </SidebarMenuItem>
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          )}

          <SidebarGroup>
            <SidebarContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuButton asChild>
                    <Link to={getAllStreamersPath()}>
                      <Users />
                      <span>{t("sidebar.community")}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarContent>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <SidebarMenu>
            <ChangeLanguage />
            <SidebarMenuItem>
              <SidebarMenuButton onClick={() => logout(true)}>
                <LogOutIcon fontSize="small" className="mr-2" />
                <span>{t("sidebar.logout")}</span>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
      </BaseSidebar>
    </Fragment>
  )
}
