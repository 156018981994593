import { Link } from "react-router-dom"
import {
  InstagramIcon,
  TwitchIcon,
  TikTokIcon,
  YoutubeIcon,
  LinkedinIcon,
} from "../../Icons"
import { useTranslation } from "react-i18next"
import { env } from "../../../utils/env"
import { DiscordIcon } from "../../Icons/DiscordIcon"
import { getFaqPath, getTermsPath } from "@/lib/getRoutePath"

type FooterProps = {
  compact?: boolean
}

export function Footer({ compact = false }: FooterProps) {
  const { t } = useTranslation()

  return (
    <div className="bottom-0 w-full bg-[#050d1f] px-10 py-6 text-card-foreground">
      {!compact && (
        <>
          <div className="flex flex-col justify-between md:container md:flex-row">
            <div className="space-y-6 text-left">
              <p className="m-0 text-xl font-bold text-white">DUOTOPLAY</p>
              <nav>
                <ul className="list-none">
                  <li>
                    <Link to={getTermsPath()}>{t("TermsOfService.title")}</Link>
                  </li>
                  <li>
                    <Link to={getFaqPath()}>{t("Faq.title")}</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="flex flex-col justify-end">
              <div className="flex justify-center gap-x-2">
                <Link to={env.DUO_INSTAGRAM} target="_blank">
                  <InstagramIcon />
                </Link>
                <Link to={env.DUO_TIKTOK} target="_blank">
                  <TikTokIcon />
                </Link>
                <Link to={env.DUO_YOUTUBE} target="_blank">
                  <YoutubeIcon />
                </Link>
                <Link to={env.DUO_TWITCH} target="_blank">
                  <TwitchIcon />
                </Link>
                <Link to={env.DUO_LINKEDIN} target="_blank">
                  <LinkedinIcon />
                </Link>
                <Link to={env.DUO_DISCORD} target="_blank">
                  <DiscordIcon />
                </Link>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="flex flex-wrap items-center justify-center gap-12 p-4 text-xs uppercase text-card-foreground">
        <div>{t("BetaVersion")}</div>

        {compact && (
          <div className="flex items-center justify-center gap-x-4">
            <Link to={getTermsPath()}>{t("TermsOfService.title")}</Link>
            <Link to={getFaqPath()}>{t("Faq.title")}</Link>
          </div>
        )}

        <span className="uppercase">
          2024, DUOTOPLAY. {t("RightsReserved")}.
        </span>
      </div>
    </div>
  )
}
