import { Skeleton } from "@/components/ui/skeleton"
import clsx from "clsx"

type UserPictureProps = {
  alt?: string
  src?: string
  size: "small" | "medium" | "big"
  className?: string
}

const sizeValues = {
  small: 32,
  medium: 150,
  big: 200,
}

const sizeClasses = {
  small: "size-8",
  medium: "size-[150px]",
  big: "size-[200px]",
}

export function UserPicture({
  alt,
  src,
  size = "big",
  className,
}: UserPictureProps) {
  if (!alt || !src) {
    return (
      <Skeleton className={clsx(sizeClasses[size], "rounded bg-slate-700")} />
    )
  }

  return (
    <img
      src={src}
      alt={alt}
      width={sizeValues[size]}
      height={sizeValues[size]}
      className={clsx(sizeClasses[size], "animate-fade-in rounded", className)}
    />
  )
}
