import { Card, CardContent } from "@/components/ui/card"
import { ReactNode } from "react"

type MatchesPanelProps = {
  title: string | ReactNode
  children: ReactNode
}
export const MatchesPanel = ({ title, children }: MatchesPanelProps) => {
  return (
    <section className="space-y-4">
      <h3 className="flex items-center space-x-2 text-2xl font-semibold">
        {title}
      </h3>

      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </section>
  )
}
