import { Routes } from "./routes/Routes"
import { QueryClientProvider } from "@tanstack/react-query"
import { queryClient } from "./lib/reactQuery"
import { Providers } from "./services/providers"
import { InitialHelmet } from "./components/InitialHelmet"

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Providers>
        <InitialHelmet />
        <Routes />
      </Providers>
    </QueryClientProvider>
  )
}
