import { TAccount, TPlayer, TProduct } from "../../../types"
import { Stripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { CheckoutForm } from "../../../pages/payment/CheckoutForm"
import { FirstStep } from "./firstStep"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { PostOrder } from "../../../services/request/Order"
import { useAuthContext } from "../../../context/auth"
import { useState } from "react"
import StepHeader from "./StepHeader"
import { stripeCheckoutStyles } from "../../../styles/stripeCheckout"
import i18n from "../../../i18n"
import { Spinner } from "@/components/Spinner"

type PaymentStepsProps = {
  stripePromise?: Stripe | null
  product?: TProduct
  talent?: TAccount
}

export default function PaymentSteps({
  product,
  talent,
  stripePromise,
}: PaymentStepsProps) {
  const { t } = useTranslation()
  const { user } = useAuthContext()
  const [personalData, setPersonalData] = useState<TPlayer>()

  const { data: order, isLoading } = useQuery({
    queryKey: ["PostOrder"],
    queryFn: () =>
      PostOrder({
        product_id: product!.id!,
        account_id: user.userId,
        account_talent_id: talent!.id!,
        game_access: personalData?.username!,
      }),
    enabled: !!product && !!talent && !!personalData,
  })

  return (
    <div className="flex grow flex-col rounded-sm border border-slate-800">
      <div className="flex justify-center pt-8">
        <div className="flex w-full max-w-[400px] justify-center">
          <StepHeader
            icon={null}
            title={t("CheckOut.GameAccount.Title")}
            stepIndex={1}
            currentStep={!order ? 1 : 2}
          />
          <StepHeader
            icon={null}
            title={t("CheckOut.Payment.Title")}
            stepIndex={2}
            currentStep={!!order ? 2 : 1}
          />
        </div>
      </div>
      <div className="flex items-center justify-center px-6 md:px-10">
        {isLoading ? (
          <div className="absolute flex h-full w-full items-center justify-center">
            <Spinner className="size-10 text-secondary" />
          </div>
        ) : (
          <div className="w-full max-w-[500px]">
            <FirstStep disabled={!!order} setData={setPersonalData} />
            {order && stripePromise && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: order.client_secret,
                  appearance: stripeCheckoutStyles.appearance,
                  locale: i18n.language === "en" ? "en" : "pt-BR",
                  loader: "always",
                }}
              >
                <CheckoutForm orderId={order.order_id} />
              </Elements>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
