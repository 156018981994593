import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Switch,
} from "react-router-dom"
import { CallbackLoginPage } from "../pages/login/CallbackLoginPage"
import { Login } from "../pages/login/LoginPage"
import { PrivateRoute } from "./PrivateRoute"
import ObsPage from "../pages/obs"
import { Completion } from "../pages/payment/CompletionPage"
import { Payment } from "../pages/payment/PaymentPage"
import Overview from "../pages/overview"
import { TalentPage } from "../pages/talent"
import { LayoutView, LayoutViewSecondary } from "../components/layout"
import Profile from "../pages/profile"
import Terms from "../pages/terms"
import FAQ from "../pages/faq"
import { Talents } from "../pages/talents"
import Duo from "@/pages/duo"
import CreateDuo from "@/pages/duo/create"
import UpdateDuo from "@/pages/duo/update"
import { getAllStreamersPath, getCreateDuoPath, getDuoMatchesPath, getEditProfilePath, getLoginPath, getOverviewPath, getStreamerProfilePath, getEditDuoPath, getTermsPath, getFaqPath, getObsPath, getAuthCallbackPath, getPaymentPath, getCompletionPath } from "@/lib/getRoutePath"

export function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Navigate to={getLoginPath()} replace />} />
        <Route path={getLoginPath()} Component={Login} />
        <Route path={getObsPath()} Component={ObsPage} />
        <Route
          path={getAuthCallbackPath()}
          Component={CallbackLoginPage}
        />
        <Route
          path={getTermsPath()}
          element={
            <LayoutViewSecondary>
              <Terms />
            </LayoutViewSecondary>
          }
        />
        <Route
          path={getFaqPath()}
          element={
            <LayoutViewSecondary>
              <FAQ />
            </LayoutViewSecondary>
          }
        />
        <Route
          path={getAllStreamersPath()}
          element={
            <LayoutViewSecondary>
              <Talents />
            </LayoutViewSecondary>
          }
        />
        <Route
          path={getPaymentPath()}
          element={
            <PrivateRoute
              Component={
                <LayoutViewSecondary>
                  <Payment />
                </LayoutViewSecondary>
              }
            />
          }
        />
        <Route
          path={getCompletionPath()}
          element={
            <PrivateRoute
              Component={
                <LayoutViewSecondary>
                  <Completion />
                </LayoutViewSecondary>
              }
            />
          }
        />
        <Route
          path="/portal"
          element={<Navigate to={getOverviewPath()} replace />}
        />
        <Route
          path="/portal/resume"
          element={<Navigate to={getOverviewPath()} replace />}
        />
        <Route
          path={getOverviewPath()}
          element={
            <PrivateRoute
              Component={
                <LayoutView>
                  <Overview />
                </LayoutView>
              }
            />
          }
        />

        <Route
          path={getDuoMatchesPath()}
          element={
            <PrivateRoute
              Component={
                <LayoutView>
                  <Duo />
                </LayoutView>
              }
            />
          }
        />

        <Route
          path={getCreateDuoPath()}
          element={
            <PrivateRoute
              Component={
                <LayoutView>
                  <CreateDuo />
                </LayoutView>
              }
            />
          }
        />

        <Route
          path={getEditDuoPath()}
          element={
            <PrivateRoute
              Component={
                <LayoutView>
                  <UpdateDuo />
                </LayoutView>
              }
            />
          }
        />

        <Route
          path={getEditProfilePath()}
          element={
            <PrivateRoute
              Component={
                <LayoutView>
                  <Profile />
                </LayoutView>
              }
            />
          }
        />

        <Route
          path={getStreamerProfilePath()}
          element={
            <LayoutViewSecondary>
              <TalentPage />
            </LayoutViewSecondary>
          }
        />
      </Switch>
    </BrowserRouter>
  )
}
