import { BackButton } from "@/components/BackButton";
import { SectionTitle } from "@/components/SectionTitle";
import { getOverviewPath } from "@/lib/getRoutePath";
import { TProduct } from "@/types";
import { ProductDetails } from "@/views/Duo/ProductDetails";
import { ReactNode } from "react";

export function DuoPage({
  children,
  title,
  sideSlot,
  product,
}: { title: ReactNode; children: ReactNode; sideSlot: ReactNode; product: Partial<TProduct> }) {

  return (
    <div className="flex flex-col space-y-10 md:container">
      <div className="flex justify-between items-center">
        <SectionTitle>
          <BackButton to={getOverviewPath()} />
          <div className="flex flex-col">
            <div className="text-sm text-muted-foreground">
              {title}
            </div>
            <div className="text-lg">{product.title}</div>
          </div>
        </SectionTitle>
      </div>
      <div className="flex-wrap justify-between gap-6 md:flex">
        <div className="grow sm:min-w-[300px] md:min-w-[500px]">
          {children}
        </div>
        <div className="flex w-full max-w-[300px] grow flex-col gap-2">
          <ProductDetails
            product={product}
          />

          {sideSlot}
        </div>
      </div>
    </div>
  )
}