import { TAccount, TWhisperDefault, WhisperDefaultSchema } from "../../types"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useUser } from "../../hook/useUser"
import { PostDefaultWhisper } from "../../services/request/Whisper"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { queryClient } from "../../lib/reactQuery"
import {
  Card,
  CardDescription,
  CardTitle,
  CardContent,
  CardHeader,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { toast } from "sonner"
import {
  Form,
  FormControl,
  FormItem,
  FormField,
  FormLabel,
} from "@/components/ui/form"
import { Spinner } from "@/components/Spinner"

export function DefaultWhisper() {
  const { data: user } = useUser()
  const { t } = useTranslation()

  const form = useForm<TWhisperDefault>({
    resolver: zodResolver(WhisperDefaultSchema),
    defaultValues: {
      whisper_message: user?.whisper_message || "",
    },
  })

  const { mutateAsync: mutateAsyncPostDefaultWhisper, isPending } = useMutation(
    {
      mutationFn: PostDefaultWhisper,
    },
  )

  const handleSave = async (content: TWhisperDefault) => {
    try {
      mutateAsyncPostDefaultWhisper(
        { userId: user?.id!, content },
        {
          onSuccess: () => {
            const cached = queryClient.getQueryData<TAccount>(["user"])
            queryClient.setQueryData<TAccount>(
              ["user"],
              cached && { ...cached, whisper_message: content.whisper_message },
            )
            toast.success(t("Profile.DefaultWhisper.Success"))
          },
        },
      )
    } catch (error) {
      toast.error(t("Profile.DefaultWhisper.error"))
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Profile.DefaultWhisper.title")}</CardTitle>
        <CardDescription>
          {t("Profile.DefaultWhisper.subtitle")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSave)}
            className="w-full space-y-2"
          >
            <FormField
              control={form.control}
              name="whisper_message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Profile.DefaultWhisper.title")}</FormLabel>
                  <FormControl>
                    <Textarea
                      rows={4}
                      maxLength={300}
                      {...field}
                      defaultValue={user?.whisper_message || ""}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="w-full text-right">
              <Button disabled={isPending} type="submit">
                {isPending && <Spinner />}
                {t("Profile.DefaultWhisper.save")}
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
